.NavBar-Component{
    width: 60px;
    height: 100vh;
    background-color:  var(--primary);
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    display: none;
}

.NavBar-Logo{
    box-sizing: border-box;
    width: 70%;
    margin-left: 15%;
    height: 60px;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    background-color: var(--primary);

}

.NavBar-Logo > img{
    width: 100%;
    /* border-radius: 50%; */

}

.NavBar-Links{
    /* background-color: red; */
    width: 100%;


}
.NavBar-Links-movil{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    gap: 8px;
}

.NavBar-Link{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.NavBar-Link-movil{
    display: flex;
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

.NavBar-LinkIcon{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.NavBar-LinkIcon-movil{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.NavBar-LinkIconSelected{
    color: var(--primary);
}

.NavBar-LinkIconSelected-movil{
    color: var(--primary);

}

.NavBar-Selected{
    width: 90%;
    height: 100%;

    top: 0;
    left: 8px;
    border-radius:  10px 0 0 10px;
    position: absolute;
    background-color: #F2F2F2;
}

.NavBar-LinkIconSelected-movil{
    width: 100%;
    height: 90%;

    top: 0;
    border-radius:  0 0 10px 10px;
    position: absolute;
    background-color: #F2F2F2;
}

.NavBar-Bell{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.NavBar-Close{
    width: 100%;
    height: 50px;
    justify-self: end;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.NavBar-Close-movil{
    display: flex;
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}

.NavbarMovil-component{
    display: flex;
    position: fixed;
    width: 100%;
    height: 60px;
    background-color:  var(--primary);
    bottom: 0;


}

@media (min-width:720px){
    .NavBar-Component{
        display: flex;
    }

    .NavbarMovil-component{
        display: none;
    }
    
  
  }