

.TableRequest-SensorsSection{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.TableRequest-sensors{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}