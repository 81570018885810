.imageSelect-component{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
}

.imageSelect-item{
    width: 80px;
    height: 80px;

    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

}

.imageSelect-itemSelected{
    width: 80px;
    height: 80px;

    border: 1px solid #1b68ab;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.imageSelect-item img, .imageSelect-itemSelected img{
    width: 80%;
    margin-left: 10%;
    
}