.ModalContent > .ant-modal-content{
    padding: 0!important;
}

.ModalContentStepHeader{
    width: 100%;
    height: 50px;
    position: relative;
    display: none;
}

.ModalContentStepHeader-step{
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
}

.ModalContentStepHeader-CloseButton{
    position: absolute;
    right: 20px;
    top: 20px;
    
}

.ModalContent-body{
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

@media (min-width:720px){
    .ModalContentStepHeader{
        display:flex;
    }
    
  
  }