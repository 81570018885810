:root {
  --primary: #00509e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F2F2;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  scrollbar-width: thin;
  scrollbar-color: var(--primary) #ededed;
  scrollbar-arrow-color: #ededed;
  
}

.ant-segmented-item-selected{
  color: #fff !important;
}

.scrollbar-component{
  scrollbar-width: thin !important;
  scrollbar-color: var(--primary) #ededed !important;
  scrollbar-arrow-color: #ededed !important;
}

.Select-Text-Style{
  -webkit-user-select: text!important; /* Safari */
  -moz-user-select: text!important; /* Firefox */
  -ms-user-select: text!important; /* IE10+/Edge */
  user-select: text!important; /* Standard */
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar-track, .scrollbar-component::-webkit-scrollbar-track, .ant-card-body::-webkit-scrollbar-track  {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ededed;
}
    
body::-webkit-scrollbar, .scrollbar-component::-webkit-scrollbar, .ant-card-body::-webkit-scrollbar-track {
  width: 8px;
  background-color: #ededed;
}
    
body::-webkit-scrollbar-thumb, .scrollbar-component::-webkit-scrollbar-thumb, .ant-card-body::-webkit-scrollbar-track {

  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--primary);
}
