
.circle-graph:hover ~ .display-hover-graph{
    display: flex;
}

.g-element-graph {
    width: 100px;
    height: 40px;

    background-color: #fff;
    fill: #fff;
}

.display-hover-graph{
    display: none;

}

.recharts-active-dot{
    display: none !important; 
}

.subsidiaryGraph{
    height: 100%;
    width: 100%;
}

 .echarts-parent, .subsidiaryGraph{
    width: 100% !important;
    height: 100% !important;
}

.subsidiaryGraph > div >  canvas{
    width: 100% !important;
    height: 100% !important;
}