.SiteCard{
    height: calc(100vh - 2rem);
}

.sitesPage-list{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    gap: 12px;

    overflow-y: scroll;
}

@media (min-width:720px){


    .SiteCard{
        height: calc(100vh - 2rem);
    }
    .sitesPage-list{
        overflow-y: hidden;
    }

  }

  


