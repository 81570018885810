.ResellerDetail-bottom{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    height: auto;
}

.OrganizationsPage-reseller-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
    height: 300px;
    overflow-y: scroll;
    align-content: flex-start;
}

.ResellerDetail-HeaderSection{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    height: auto;
}

.ResellerDetail-info{
    width: 100%;
    height: auto;
    order: 2;
}

.ResellerDetail-cards{
    width: 100%;
    order: 1;
    margin-bottom: 1rem;

}

.ResellerDetail-organization{
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
}

.ResellerDetail-sensors{
    width: 100%;
    height: 100%;
}
.ResellerDetail-sensors-Table{
    height: 300px;
    overflow-y: scroll;
}

@media (min-width:720px){
    .ResellerDetail-HeaderSection{
        height: 20vh;
        flex-wrap: nowrap;
    }

    .ResellerDetail-bottom{
        flex-wrap: nowrap;
        height: calc(100vh - 20vh - 3rem);
    }

    .ResellerDetail-cards{
        width: 39%;
        order: 2;
        margin-bottom: 0;
    }

    .ResellerDetail-info{
        width: 60%;
        height: 20vh;
        order: 1;

    }

    .ResellerDetail-organization{
        width: 60%;
        height: 100%;
        margin-bottom: 0;
    }

    .ResellerDetail-sensors{
        width: 39%;
        height: 100%;
    }

    .OrganizationsPage-reseller-list{
        height: 100%;
    }

    .ResellerDetail-sensors-Table{
        height: 100%;
        overflow-y: scroll;
    }

  }