.LoginForm-Component{
    width: 80vw;
    height: 90vh;

    background-color: #fff;
    display: flex;

    border-radius: 10px;
    overflow: hidden;
    
}

.LoginForm-Component > div{
    width: 100%;
    height: 100%;


}

.LoginForm-Form > img{
    width: 250px;
    object-fit: contain;
    border-radius: 10px;

}

.LoginForm-FormSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.LoginForm-Component > div:first-child{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}



.LoginForm-Component > div:last-child{
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

}

.LoginForm-Component > div >img{
    object-fit: fill;
    width: 100%;
    height: 100%;

}

.LoginForm-Form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    gap: 30px;

}

.LoginForm-image{
    display: none !important;
}

@media (min-width:1000px){
    .LoginForm-image{
        display: block !important;
    }

    .LoginForm-Component > div{
        width: 50%;
    }


  }