.CreateAddSensorQty-Generate{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.CreateAddSensorQty-QtySensors{
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.CreateAddSensorQty-Buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.CreateAddSensorQty-Table{
    max-height: 350px;
    overflow-y: scroll;
}