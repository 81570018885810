.listSingleCardInformation{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}


.fullListCardInformation{
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.SingeCardInformation{
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0.8rem 1rem;
    gap: 1rem;

    position: relative;

}

.SingeCardInformation-details{
    position: absolute;
    bottom:5px;
    right: 5px;
}

.SingeCardInformation-title{
    font-size: 1.2rem;
}

.SingeCardInformation-value{
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;

}

.doubleCardInformation-value{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}