.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sliderForm > .ant-form-item-row > .ant-form-item-control{
  padding: 0 15px;
}

.Layout-Card-Paginate{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-self:stretch;
  }

.single-Filter{
 width: 100%;
 margin-right:"15px"
}

.double-Filter{
  width: 47%;
 }

.Card-Full-Width{
  width: 100%;
}

.Card-f-cards{
  width: 200px;
  flex: 0 0 auto;
  margin-right: 12px;
}

.Card-dreseller-cards{
  width: 200px;
  flex: 0 0 auto;
  margin-right: 12px;
}

.Card-dasset-cards{
  width: 200px;
  flex: 0 0 auto;
  margin-right: 12px;
}



.Card-middle-Width{
  width: 100%;
}

@media (min-width:720px){
  .Card-f-cards{
    width: 24%;
  }
  .Card-dasset-cards{
    width: 32%;
    margin-right: 0;
  }
  .single-Filter{
    width: 20%;
   }
   .double-Filter{
    width: 20%;
   }
  .Card-Full-Width{
    width: 24%;
  }
  .Card-middle-Width{
    width: 48%;
  }
  .Card-dreseller-cards{
    width: 32%;
    
    margin-right: 0;
  }


}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centerPaginate{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;

  margin-top: auto;
}

.listElementsContainer{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
}
