.DashboardReseller-layout{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.DashboardOrganizacion-TopLayout{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.DashboardOrganizacion-Layout{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.DashboardReseller-layout-one{
    width: 100%;
}

.DashboardReseller-layout-two{
    width: 100%;
    margin-top: 1rem;
}

.DashboardReseller-card-organization{
    width: 100%;
    height: auto;
}

.DashboardReseller-organization-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
    height: 300px;
    overflow-y: scroll;
    align-content: flex-start;
}

.DashboardReseller-cards-info{
    height: auto;
}

.DashboardReseller-card-sensor{
    height: auto;
}

.DashboardReseller-table-sensors{
    height: 300px;
    overflow-y: scroll;
}

.dashboardOrganizacion-card-site{
    width: 100%;
    height: 70vh;
}

.dashboardOrganizacion-card-divided{
    width: 100%;
    height: auto;
}

.dashboardOrganizacion-card-asset{
    width: 100%;
    height: 70vh;
}

.dashboardOrganizacion-card-graph{
    width: 100%;
    height: 70vh;
    margin-top: 1rem;
}

.dashboardComponentCard{
    height: auto;
}

@media (min-width:720px){
    .DashboardReseller-card-sensor{
        height: calc(100vh - 15vh - 3rem);
    }
    .DashboardReseller-table-sensors{
        height: 100%;
    }

    .DashboardReseller-cards-info{
        height: auto;
        height: 15vh;
    }

    .DashboardOrganizacion-Layout{
        flex-wrap: nowrap;
    }

    .DashboardReseller-layout{
        flex-wrap: nowrap;
    }

    .DashboardReseller-layout-one{
        width: 49%;
    }
    .DashboardReseller-layout-two{
        width: 50%;
        margin-top: 0; 
    }
    .DashboardOrganizacion-TopLayout{
        width: 50%;
    }
    .DashboardReseller-organization-list{
        height: 100%;
    }
    .DashboardReseller-card-organization{
        height: calc(100vh - 2rem);
    }
    .dashboardOrganizacion-card-site{
        height: calc(100vh - 2rem);
    }
    .dashboardOrganizacion-card-divided{
        height: calc(50vh - 2rem);
    }
    .dashboardOrganizacion-card-asset{
        height: 100%;
    }
    .dashboardOrganizacion-card-graph{
        height: calc(50vh - 1rem);
        margin-top: 0;
    }

    .dashboardComponentCard{
        height: calc(100vh - 2rem);
    }
  }