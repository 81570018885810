.ButtonIcon-Component{
    border: none;
    box-sizing: border-box;
    padding: 8px 8px;
    background: transparent;
    border: 1px solid #E9E9E9;
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4px;

    cursor: pointer;
}