.historic-table{
    height: 80%;
    overflow-y: scroll;
    
}

@media (min-width:720px){
    .historic-table{
        height: 100%;
    }

  }