.ResellerPage-list{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    gap: 12px;

    overflow-y: scroll;
}

.reseller-card{
    height: calc(100vh - 2rem);

}

@media (min-width:720px){


    .ResellerPage-list{
        overflow-y: hidden;
    }

  }