.AssetDetail-bottom{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.AssetDetail-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 12px;
}

.AssetDetail-CardsSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;
    gap: 12px;
    order: 1;
    margin-bottom: 1rem;

}

.AssetDetail-top-card{
    width: 100%;
    order: 2;
}

.AssetDetail-bottom-lcard{
    width: 100%;
    margin-bottom: 1rem;
}

.AssetDetail-bottom-rcard{
    width: 100%;
}

@media (min-width:720px){
    .AssetDetail-top-card{
        width: 35%;
        margin-bottom: 0;
        order: 1;
    }

    .AssetDetail-CardsSection{
        width: 64%;
        flex-wrap: wrap;
        order: 2;
        margin-bottom: 0;

    }

    .AssetDetail-top{
        flex-wrap: nowrap;
    }

    .AssetDetail-bottom{
        flex-wrap: nowrap;
    }

    .AssetDetail-bottom-lcard{
        width: 49.5%;
        margin-bottom: 0;
    }
    
    .AssetDetail-bottom-rcard{
        width: 49.5%;
    }
  }