.mapMarker{
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background: #008dc0;
    border: 2px solid #0c7aa1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.mapMarker:hover{
    opacity: 1;
}