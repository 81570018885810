.Layout-component{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}


.Layout-children{
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 60px;
    /* height: calc(100vh - 60px); */
    margin-left: 0;
    height: 100%;

    padding: 1rem;

}

.Layout-children-Complete{
    box-sizing: border-box;
    width: 100vw;
    margin-left: 0;
    height: 100vh;
    padding: 0;
}

@media (min-width:720px){
    .Layout-children{
        width: calc(100vw - 60px);
        margin-left: 60px;
        margin-bottom: 0;
    }
  
  
  }