.BatteryIndicator-Container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}

.BatteryIndicator{
    position: relative;
    width: 15px;
    height: 8px;
    border: 1.3px solid #000;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: center;
    border-radius: 2px;
}

.BatteryIndicator-End{
    position: absolute;
    width: 2px;
    height:80%;
    border-radius: 0 1px 1px 0;
    right: -2.5px;
}