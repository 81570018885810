
.ReportHeader{
    background-color: var(--primary);
    color: #fff;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.ReportLogo{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.ReportHeader-left{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.ReportHeader-titleText{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
}

.ReportHeader-right{
    margin-right: 2rem;
}

.ReportFooter{
    
    background-color: var(--primary);
    color: #fff;
    padding: 1rem;
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.ReportFooter-page{
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.ReportFooter-info{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.ReportCardAsset{
    width: 40%;
    border-radius: 2px;
    padding: 1rem;
    box-sizing: border-box;
    border: 2px solid var(--primary);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap; 
    gap: 12px;
}

.ReportCardAsset-title{
    text-align: center;
    font-weight: bold;

}

.ReportCardSensor-title{
    background-color: var(--primary);
    color: #fff;
    text-align: center;
}

.ReportCardSection{
    box-sizing: border-box;
    padding: 6px;
}

.ReportCardSection-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 1px solid #00000080;
}

.ReportCardSection-list > span{
    width: 50%;
    padding: 3px;
}

.ReportCardSection-list > span:first-child{
    border-right: 1px solid #00000080;
}



.ReportCardSensor{
    box-sizing: border-box;
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border: 2px solid #00000080;
}


.ReportCardSensor-SectionBorder{
    display: flex;
    width: 100%;
    border: 2px solid #00000080;

}

.ReportCardSensor-SectionBorder > div{
    border-bottom: none !important;

}

.ReportLayoutDetail{
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
}

.AlarmDetail-Component{
    display: flex;
    box-sizing: border-box;
    margin-bottom: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
    width: 25%;
}

.AlarmDetail-Component > div{
    box-sizing: border-box;
    padding: 2px;
    width: 100%;
    border: 2px solid #00000080;
    border-right: none;
}

.ReportTaskDetail-list{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.ReportTaskDetail-AlarmList > div:last-child{
border-right: 2px solid #00000080;
}

.ReportTaskDetail-AlarmList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ReportTaskDetail-sublist{
    width: 47%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 6px;
    box-sizing: border-box;
}

.ReportSub-title{
    font-weight: bold;
    font-size: 1.5rem;
}

.ReportNotes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1rem;
}
.ReportNotes-section{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
}

.ReportNotes-left{
    width: 75%;
}

.ReportNotes-right{
    width: 23%;
}





.ReportNotes-section > div{
    width: 100%;
    height: 120px;

    border: 2px solid var(--primary);
}


.ReportTempCard{
    width: 100%;
    padding: 2px;
    border: 1px solid #00000080;
}

.ReportTempList-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3px;
}

.ReportTempList-list >div {
    width: 30%;
}

.ReportTempList-list >div:nth-child(2n) {
    background-color: #D9D9D9;
}

.ReportTempList-list >div:nth-child(2n+1) {
    color: #fff;
    background-color: var(--primary);
}

.ReportLayot{
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
}

.Report-divided-layout{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: space-between;
}

.ReportView-container{
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
}

.page-break{
    margin: 10px 0;
    border-bottom: 2px dashed #D9D9D9;
}

.ReportTemps-container{
    min-height: 800px;
}