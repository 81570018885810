.Setting-Component{
    width: 100%;
    height: 100%;
}

.Settings-body{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}


.Setting-Content{
    width: calc(100% - 256px);
    height: 100%;
}