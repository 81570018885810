.Profile-Form{
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    
}

.Profile-Form-div{
    width: 60%;

    
}