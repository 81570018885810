
.grafoBusqueda-circleClick{
    cursor: pointer;

    transition: all 0.5s ease-in-out;
}

.grafoBusqueda-circleBlue{

    fill: #2e39bf;

}

.grafoBusqueda-circleNoneBlue{

    opacity: 0;

}

.grafoBusqueda-noneactiveElements{
    opacity: 0;
}

.grafoBusqueda-activeElements{
    opacity: 1;
}

.grafoBusqueda-circleW{

    stroke: #000;
}


.textoGrafoBusqueda{
    fill: red;
    /* transform-origin: center; */

    /* transform-origin: center; */
    -webkit-transform-style: preserve-3d;
    transform:rotateX(180deg);

}

.grafoBusqueda-cTexto{
    /* background-color: rgba(255, 0, 0, 0.164); */
    position: relative;
    width: 200px;
    height: 50px;
    font-size: 18px;
}

.grafoBusqueda-centerText{
    position: relative;
    width: 100%;
    height: 50px;
    font-size: 18px;
    text-align: center;
}

.grafoBusqueda-TextoN{
    position: absolute;
    left: 10%;

}

.grafoBusqueda-Texto{
    position: absolute;
    text-align: right;
    top: 0;
    left: 10%;
    transform-origin: center;
    transform: rotateY(180deg) rotateX(180deg);

}

.spin{
    background-color: #2e39bf;
}

.grafoBusqueda-grafo{
    width: 100%;
    height: 100%;
    margin-left: 0!important;
}

@media (min-width:720px){
    .grafoBusqueda-grafo{
        width: 50%;
        height: 100%;
        margin-left: 25% !important;
    }
  
  
  }