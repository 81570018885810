.users-table{
    width: 100%;
    overflow-y: scroll;
}

@media (min-width:720px){


    .users-table{
        overflow-y: hidden;
    }

  }