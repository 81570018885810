.autocomplete-container{
    width: 100%;
}

.MapInputSelect-input{
    width: 100%;
    height: 30px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 6px;

    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    padding-left: 5px;
    opacity: 1;
}

.MapInputSelect-input:hover{
    opacity: 1;
}