.ElementCard{
    background-color: #fff;

    /* width: 340px; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    border-radius: 8px;
    position: relative;
    border: 1px solid #e9e9e9 ;
}

.NewElementCardHeader{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
}

.NewElementCardHeaderTitle{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.NewElementCard{
    background-color: #fff;

    /* width: 340px; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    position: relative;
    border: 1px solid #e9e9e9 ;
    box-sizing: border-box;
    padding: 1rem;

}

.ElementCard-Right{
    width: 30%;
    border-right: 1px solid #e9e9e9 ;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.NewElementCardImage{
    width: 50px;
    height: 50px;
    /* border: 1px solid #e9e9e9 ; */

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    /* border-radius: 50%; */
    box-sizing: border-box;

}
.NewElementCardImage> img{
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.NewElementCardFooter{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    justify-content: space-between;
    align-items: flex-end;
}

.NewElementCardTag{
    padding: 4px 8px;
    border-radius: 4px;
    /* background-color: #e9e9e9; */
    border: 1px solid #e9e9e9;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
font-weight: bold;
    color: #000;
}

.NewElementCardStatus{
    font-weight: bold;
    font-size: 1.5rem;
}

.NewElementCardTagStatus{
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    color: #fff;

    box-sizing: border-box;
}


.ElementCard-Right > img{
    width: 80%;
    margin-left: 10%;
    object-fit: contain;
}

.ElementCard-Left{
    width: 70%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ElementCard-Status{
    width: 6px;
    height: 6px;
    border-radius: 6px;
    bottom: 10px;
    right: 10px;
    position: absolute;
}

.ElementCard-title{
    font-weight: bold;
}

.ElementCard-lineElements{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
}
