.OrganizationDetail-table{
    height: 80%;
    overflow-y: scroll;
}

@media (min-width:720px){
    .OrganizationDetail-table{
        height: 100%;
        overflow-y: hidden;
    }
}