.AssetDetailCard{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

}

.AssetDetailCard-left{
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border-right: 1px solid #e9e9e9 ; */

}

.AssetDetailCard-left > img{
    width: 40%;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    object-fit: contain;
}

.AssetDetailCard-right{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 12px;


    align-items: center;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.AssetDetailCard-rightElement{
    /* border-bottom: 1px solid #e9e9e9 ; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    box-sizing: border-box;
    padding: 4px;

}

.AssetDetailCard-rightElementEnd{
    box-sizing: border-box;
    padding: 4px;
}

@media (min-width:720px){
    .AssetDetailCard{
        flex-wrap: nowrap;
    }
    .AssetDetailCard-left{
        width: 20%;
        height: 100%;
    }

    .AssetDetailCard-left > img{
        width: 100%;
    }

    .AssetDetailCard-right{
        width: 80%;
    }
  }