.Menu-Component{

    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 12px;
}


.ant-modal-content{
    /* padding: 0!important; */
}

.HeaderMenu-Component{
    width: 100%;
    height: 30px;
    position: relative;
}

.HeaderMenu-CloseButton{
    position: absolute;
    right: 10px;
    top: 5px;
    
}