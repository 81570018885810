.UserManageForm-Component{
    width: 80vw;
    height: 90vh;

    background-color: #fff;
    display: flex;

    border-radius: 10px;
    overflow: hidden;
    
}

.UserManageForm-Component > div{
    width: 50%;
    height: 100%;


}

.UserManageForm-Form > img{
    width: 100px;
    object-fit: contain;
    border-radius: 10px;


}

.UserManageForm-Component > div:last-child{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}



.UserManageForm-Component > div:first-child{
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

}

.UserManageForm-Component > div >img{
    object-fit: cover;
    width: 100%;
    height: 100%;

}

.UserManageForm-Form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    gap: 30px;

}